import http from "@/utils/http";
import { getApiUrl } from "@/utils/url";
import { defer } from "react-router-dom";
import { proxy } from "valtio";

const state = proxy<{ user: any; config: any }>({
  user: null,
  config: {},
});

const actions = {
  load: async () => {
    let user = state.user;
    if (!user) {
      user = await actions.getOwner();
    }
    return defer({ user });
  },
  getOwner: async () => {
    try {
      const res = await http.get(`${getApiUrl()}/api/user/owner`);
      if (res?.code === 200) {
        state.user = res?.data ?? null;
        return res?.data;
      }
    } catch {
      return null;
    }
  },
  updateOwner: async (data: any) => {
    const res = await http.patch(`${getApiUrl()}/api/user/owner`, { data });
    if (res?.code === 200) {
      await actions.getOwner();
    }
    return res;
  },
  pass: async (data: any) => {
    return await http.patch(`${getApiUrl()}/api/user/pass`, {
      data,
    });
  },
  close: async () => {
    return await http.delete(`${getApiUrl()}/api/user/close`);
  },
  login: async (data: any) => {
    const res = await http.post(`${getApiUrl()}/api/user/login`, {
      data,
    });
    if (!!res?.data?.token) {
      localStorage.setItem("token", `${res.data.type} ${res.data.token}`);
      localStorage.setItem("expire", res.data.expire);
      await actions.getOwner();
    }
    return res;
  },
  logout: () => {
    localStorage.setItem("token", "");
    state.user = null;
    window.location.href = "/";
  },
  invitee: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/user/invitee`, {
      data,
    });
  },
};

export default {
  state,
  ...actions,
};
